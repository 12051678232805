import InboxIcon from '@mui/icons-material/MoveToInbox';
import HomeIcon from '@mui/icons-material/Home';
import GroupIcon from '@mui/icons-material/Group';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FolderSharedIcon from '@mui/icons-material/FolderShared';

const NavigationItems =  [
	{
		display: 'All Files (284)',
		icon: <HomeIcon />,
		path: '/'
	},
	{
		display: 'My Files',
		icon: <InboxIcon />,
		path: '/my-files'
	},
	{
		display: 'Shared with me',
		icon: <FolderSharedIcon />,
		path: '/shared-with-me'
	},
	{
		display: 'Favorite',
		icon: <FavoriteIcon />,
		path: '/favorites'
	},
	{
		display: 'Contacts',
		icon: <GroupIcon />,
		path: '/contacts'
	}
];

export default NavigationItems;