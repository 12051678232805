import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectStatus } from '../redux/slices/authSlice';
import { Grid, Box, TextField, Typography, Card, CardActionArea } from '@mui/material';
import Orders from '../components/Orders';
import SearchIcon from '@mui/icons-material/Search';
import TimelineIcon from '@mui/icons-material/Timeline';
import AddCircleIcon from '@mui/icons-material/AddCircle';

export default function Dashboard() {
  const authStatus = useSelector(selectStatus);
  const navigate = useNavigate();

  useEffect(() => {
    if (authStatus === 'LOGGED_OUT') {
      navigate('/login');
    }
  }, [authStatus, navigate]);

  return (
    <>
      <Grid
        container
        flexDirection='column'
      >
        <Grid
          container
          style={{
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
          }}
          flexDirection='row'
          flexWrap='nowrap'
        >
          <Grid
            container
            flexWrap='nowrap'
            style={{
              padding: '10px',
              paddingLeft: '40px',
              width: '280%'
            }}
          >
            <SearchIcon />
            <TextField
              placeholder="Find a file: type a name or hashtag"
              variant="standard"
              style={{
                marginLeft: '10px',
                width: '100%'
              }}
              InputProps={{
                disableUnderline: true
              }}
            />
          </Grid>
          <Grid
            container
            justifyContent='space-evenly'
            style={{
              borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
              height: '100%'
            }}
          >
            <Grid
              item
            >
              <Card
                style={{
                  height: '100%',
                  display: 'flex',
                  border: 'none',
                  boxShadow: 'none'
                }}
              >
                <CardActionArea>
                  <Grid
                    container
                    justifyContent='center'
                    alignItems='center'
                    height='100%'
                  >
                    <TimelineIcon />
                    <Typography
                      marginLeft='10px'
                    >
                      Account Analytics
                    </Typography>
                  </Grid>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid
              item
            >
              <Card
                style={{
                  height: '100%',
                  display: 'flex',
                  border: 'none',
                  boxShadow: 'none'
                }}
              >
                <CardActionArea>
                  <Grid
                    container
                    justifyContent='center'
                    alignItems='center'
                    height='100%'
                  >
                    <AddCircleIcon />
                    <Typography
                      marginLeft='10px'
                    >
                      Add Contacts
                    </Typography>
                  </Grid>
                </CardActionArea>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Box
          sx={{
            backgroundColor: 'white',
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Grid
            container
            sx={{ mt: 4, mb: 4, ml: 5 }}
            width='100%'
            height='100%'
          >
            <Grid container spacing={3}>
              {/* Recent Orders */}
              <Grid item xs={8}>
                <Orders />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </>
  );
}
