import axios from 'axios';
import { LoginType, RegisterType } from '../types/AxiosTypes';

const instance = axios.create({
	baseURL: process.env.REACT_APP_BACKEND_URL,
	withCredentials: true
});

export async function RegisterUser(input: RegisterType) {
	return await instance.post('/auth/register', input);
};

export async function LoginUser(input: LoginType) {
	return await instance.post('/auth/login', input);
};

export async function LogoutUser() {
	return await instance.post('/auth/logout');
}

export async function GetFiles() {
	return await instance.get('/file/');
}
