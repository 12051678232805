import React, { useEffect, useState } from 'react';
import { Typography, Grid } from '@mui/material';
import { CompactTable } from '@table-library/react-table-library/compact';
import { useTheme } from '@table-library/react-table-library/theme';
import { DEFAULT_OPTIONS, getTheme } from '@table-library/react-table-library/material-ui';
import { useSort } from '@table-library/react-table-library/sort';
import { GetFiles } from '../api';

const COLUMNS = [
  {
    label: 'FILE NAME',
    renderCell: (item: any) => item.fileName,
    sort: {
      sortKey: 'FILENAME'
    }
  },
  {
    label: 'SIZE',
    renderCell: (item: any) => item.size
  },
  {
    label: 'DATE UPLOADED',
    renderCell: (item: any) => item.createdAt
  },
  {
    label: 'OWNER',
    renderCell: (item: any) => item.owner,
  },
  {
    label: 'WHO HAS ACCESS',
    renderCell: (item: any) => item.nodes
  },
];

export default function Orders2() {
  const [files, setFiles] = useState<any>();
  const materialTheme = getTheme(DEFAULT_OPTIONS);
  const theme = useTheme(materialTheme);

  function onSortChange(action: any, state: any) {
    console.log(action, state);
  }

  useEffect(() => {
    async function run() {
      let response = await GetFiles();
      
      setFiles({nodes: response.data});
    }
    
    run();
  }, []);

  const sort = useSort(
    files,
    {
      onChange: onSortChange,
    },
    {
      sortFns: {
        FILENAME: (array) => array.sort((a, b) => a.fileName.localeCompare(b.fileName))
      },
    },
  );

  return files && (
    <Grid
    >
      <Typography
        component="h2"
        variant="h6"
        gutterBottom
        style={{
          color: 'black'
        }}
      >
        All Files
      </Typography>
      <CompactTable
        columns={COLUMNS}
        data={files}
        theme={theme}
        sort={sort}
      />
    </Grid>
  );
}
