import axios from 'axios';

const instance = axios.create({
	baseURL: 'http://localhost:5000/api/sigil/',
	withCredentials: true
});

export async function UploadFile(file: any) {
	let form = new FormData();
	form.append("data", file);

	return await instance.post('/encrypt', form);
};
