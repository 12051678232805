import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { LoginUser } from '../../api';
import useAlert from '../../util/hooks';
import { login, selectStatus } from '../../redux/slices/authSlice';
import { useAppDispatch } from '../../redux/hooks';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function Login() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { setError } = useAlert();
  const authStatus = useSelector(selectStatus);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    
    let response = await LoginUser({
      identifier: data.get('email') as string,
      password: data.get('password') as string,
    }).catch((error) => {
      setError(error?.response?.data?.message ?? 'Error: Please try again.', 5000);
    });

    if (response) {
      dispatch(login());
      navigate('/');
    }
  };

  useEffect(() => {
    if (authStatus === 'LOGGED_IN') {
      navigate('/');
    }
  }, [authStatus, navigate]);

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <img src="old_logo.svg" alt="OpenSigil Logo" width="100px" />
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email / Username"
            name="email"
            autoComplete="email"
            autoFocus
            defaultValue={'tooley@opensigil.com'}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            defaultValue={'c&47gP4N6ydB6lV'}
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="forgot-password" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="register" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}
