import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Dashboard from '../pages/Dashboard';
import ForgotPassword from '../pages/auth/ForgotPassword';
import Login from '../pages/auth/Login';
import Register from '../pages/auth/Register';
import { selectStatus } from '../redux/slices/authSlice';
import NavigationBar from './NavigationBar';
import { Grid } from '@mui/material';

function SubNavigation() {
  return (
    <>
      <Grid
        container
        flexWrap='nowrap'
      >
        <NavigationBar />
        <Routes>
          <Route index element={<Dashboard/>}></Route>
        </Routes>
      </Grid>
    </>
  );
}

export default function MainNavigation() {
  const authStatus = useSelector(selectStatus);
  const navigate = useNavigate();

  const pathname = window.location.pathname;

  useEffect(() => {
    const isAuthenticationPage = () => {
      return pathname === '/login' || pathname === '/register' || pathname === '/forgot-password';
    };
    
    if (authStatus === 'LOGGED_IN' && isAuthenticationPage()) {
      navigate('/');
    } else if (authStatus === 'LOGGED_OUT' && !isAuthenticationPage()) {
      navigate('/login');
    }
  }, [authStatus, navigate, pathname]);
  
  return (
    <Routes>
      <Route path="login" element={<Login />}></Route>
      <Route path="register" element={<Register />}></Route>
      <Route path="forgot-password" element={<ForgotPassword />}></Route>
      <Route path="*" element={<SubNavigation />}></Route>
    </Routes>
	);
}
