import { Alert, AlertColor, Collapse, IconButton } from '@mui/material';
import useAlert from '../util/hooks';
import CloseIcon from '@mui/icons-material/Close';

const AlertPopup = () => {
  const { text, severity, isOpen, setAlertOpen } = useAlert();

  if (text && severity) {
    return (
      <Collapse in={isOpen}>
				<Alert
					severity={severity as AlertColor}
					sx={{
						position: 'absolute',
						zIndex: 10,
						marginLeft: 'auto',
						marginRight: 'auto',
						left: 0,
						right: 0,
						textAlign: 'center',
						width: '20%'
					}}
					action={
						<IconButton
							color="inherit"
							size="small"
							onClick={() => {
								setAlertOpen(false);
							}}
						>
							<CloseIcon fontSize="inherit" />
						</IconButton>
					}
				>
					{text}
				</Alert>
			</Collapse>
    );
  } else {
    return <></>;
  }
};

export default AlertPopup;
