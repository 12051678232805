import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../store';

export interface CounterState {
  status: string
}

const initialState: CounterState = {
  status: 'LOGGED_OUT',
}

export const authSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    login: (state) => {
      state.status = 'LOGGED_IN';
    },
    logout: (state) => {
      state.status = 'LOGGED_OUT';
    } 
  },
})

export const { login, logout } = authSlice.actions

export const selectStatus = (state: RootState) => state.auth.status;

export default authSlice.reducer