import React from 'react';

import { Menu, MenuItem } from '@mui/material';
import { useAppDispatch } from '../redux/hooks';
import { LogoutUser } from '../api';
import useAlert from '../util/hooks';
import { logout } from '../redux/slices/authSlice';

interface ProfileDisplayMenuProps {
  open: boolean;
  handleClose: () => void;
  anchorEl: any;
}

export default function ProfileDisplayMenu(props: ProfileDisplayMenuProps) {
  const dispatch = useAppDispatch();
  const { setError, setSuccess } = useAlert();

  const handleLogout = async () => {
    await LogoutUser().then(() => {
      dispatch(logout())
      setSuccess('Successfully logged out.', 5000);
    }).catch((error) => {
      setError(error?.response?.data?.message ?? 'Error: Please try again.', 5000);
    });
  }

  return (
    <Menu
      open={props.open}
      anchorEl={props.anchorEl}
      onClose={props.handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <MenuItem
        onClick={props.handleClose}
      >
        Filler
      </MenuItem>
      <MenuItem
        onClick={props.handleClose}
      >
        Filler
      </MenuItem>
      <MenuItem
        onClick={props.handleClose}
      >
        Filler
      </MenuItem>
      <MenuItem
        onClick={handleLogout}
      >
        Logout
      </MenuItem>
    </Menu>
  );
}
