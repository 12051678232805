import React, { Fragment } from 'react';

import { Grid, Avatar, Typography, IconButton } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ProfileDisplayMenu from './ProfileDisplayMenu';

interface ProfileDisplayProps {
	name: string;
	subtitle?: string;
};

export default function ProfileDisplay(props: ProfileDisplayProps) {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);

	const openMenu = (event: any) => {
		setAnchorEl(event.currentTarget);
	};

	const closeMenu = () => {
		setAnchorEl(null);
	};

	return (
		<Fragment>
			<ProfileDisplayMenu
				open={open}
				handleClose={closeMenu}
				anchorEl={anchorEl}
			/>
			<Grid
				container
				alignItems='center'
				style={{
					height: '75px'
				}}
			>
				<Grid
					item
					ml={1}
				>
					<Avatar>CT</Avatar>
				</Grid>
				<Grid
					item
					ml={2}
				>
					<Grid
						container
						flexDirection='column'
					>
						<Typography
							variant='body1'
							style={{
								fontWeight: 'bold'
							}}
						>
							{props.name}
						</Typography>
						<Typography
							variant='body2'
						>
							{props.subtitle}
						</Typography>
					</Grid>
				</Grid>
				<Grid
					item
					ml='auto'
					mt={2}
					alignSelf='flex-start'
				>
					<IconButton
						onClick={openMenu}
					>
						{open ? <ArrowDropUpIcon/> : <ArrowDropDownIcon />}
					</IconButton>
				</Grid>
			</Grid>
		</Fragment>
	);
}
