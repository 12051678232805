import * as React from 'react';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import NavigationItems from './NavigationItems';
import { ListItem, ListItemButton, ListItemIcon, ListItemText, Button, Grid } from '@mui/material';
import ProfileDisplay from './ProfileDisplay';
import { useNavigate } from 'react-router-dom';
import { UploadFile } from '../api/django';

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

export default function NavigationBar() {
  const pathname = window.location.pathname;
  const inputRef = React.useRef<HTMLInputElement>(null);

  return (
    <>
      <Drawer
        open={true}
        variant="permanent"
      >
        <Grid
          container
          flexDirection='column'
          style={{
            height: '100vh'
          }}
        >
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              px: [1]
            }}
          >
            <img src="old_logo.svg" alt="OpenSigil Logo" width="125px" />
          </Toolbar>
          <Grid
            container
            justifyContent='center'
          >
            <Button
              variant="contained"
              style={{
                borderRadius: 0,
                backgroundColor: 'black',
                width: '80%',
                textTransform: 'none'
              }}
              onClick={() => inputRef.current?.click()}
            >
              Upload File
            </Button>
            <input
              type='file'
              hidden 
              ref={inputRef}
              onChange={async () => {
                if (inputRef.current && inputRef.current.files) {
                  let file = inputRef.current.files[0];

                  await UploadFile(file);
                }
              }}
            />
          </Grid>
          <List
            component="nav"
            style={{
              marginLeft: '10px',
              marginTop: '20px'
            }}
          >
            {NavigationItems.map((item, index) => (
              <NavItem
                index={index}
                display={item.display}
                icon={item.icon}
                selected={pathname === item.path}
                path={item.path}
              />
            ))}
          </List>

          <Grid
            item
            mt='auto'
            mb='15px'
            mx={3}
          >
            <ProfileDisplay
              name='Christian Tooley'
              subtitle='Admin'
            />
          </Grid>
        </Grid>
      </Drawer>
    </>
  );
}

interface NavItemProps {
  index: number;
  display: string;
  icon: any;
  selected: boolean;
  path: string;
};

function NavItem(props: NavItemProps) {
  const navigate = useNavigate();

  return (
    <>
      <Grid
        container
        flexDirection='column'
      >
        <ListItem
          key={props.index}
          disablePadding
        >
          <Grid
            style={{
              width: '4px',
              height: '25px',
              backgroundColor: props.selected ? 'black' : '',
              borderRadius: '10px'
            }}
          />
          <ListItemButton
            onClick={() => navigate(props.path)}
          >
            <ListItemIcon>
              {props.icon}
            </ListItemIcon>
            <ListItemText primary={props.display} />
          </ListItemButton>
        </ListItem>
      </Grid>
    </>
  );
};
